<template>
  <div class="user-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 账户表单 -->
        <ml-form
          ref="userRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="userForm"
          :rules="userFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">季度收益信息</el-divider>

          <el-row>
            <el-col :span="12">
              <!-- 阿米巴 -->
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="userForm.companyId"
              />
            </el-col>
            <el-col :span="12">
              <!-- 人数 -->
              <ml-input-number
                prop="userCount"
                label="人数:"
                :min="0"
                placeholder="请输入人数"
                v-model="userForm.userCount"
              />
            </el-col>
          </el-row>

          <!-- <el-row>
            <el-col :span="12">
              <ml-date-picker
                prop="contractTime"
                placeholder="请选择合同时间"
                label="合同时间:"
                type="date"
                v-model="userForm.contractTime"
              />
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row> -->

          <el-row>
            <el-col :span="12">
              <!-- 日期 -->
              <ml-date-picker
                prop="year"
                placeholder="请选择年"
                label="年:"
                type="year"
                v-model="userForm.year"
              />
            </el-col>
            <el-col :span="12">
              <!-- 季度 -->
              <ml-select
                prop="quarter"
                placeholder="请选择季度"
                :options="quarterData"
                label="季度:"
                labelName="label"
                valueName="value"
                v-model="userForm.quarter"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-合同总金额（万）元-->
              <ml-input-number
                prop="contractAmount"
                label="合同总金额（万）元:"
                placeholder="请输入合同总金额（万）元"
                v-model="userForm.contractAmount"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-职位 -->
              <ml-input-number
                prop="incomeAmount"
                label="收入（万）元:"
                placeholder="请输入收入（万）元"
                v-model="userForm.incomeAmount"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 树 -管理区域 -->
              <ml-input-number
                prop="costAmount"
                label="成本（万）元:"
                placeholder="请输入成本（万）元"
                v-model="userForm.costAmount"
              />
            </el-col>
            <el-col :span="12">
              <!-- 角色 -->
              <ml-input-number
                prop="profitAmount"
                label="利润（万）元:"
                placeholder="请输入利润（万）元"
                v-model="userForm.profitAmount"
              />
            </el-col>
          </el-row>

          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitUser"
            @click-cancel="clickCancelUser"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, searchParams, tabberHeight, formetData } from '@/utils'
export default {
  name: 'QuarterlyEarningAction',
  setup() {
    const { commit, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    // 获取路径参数
    const { earningId } = route.query
    // 设置导航标题文本
    const content = ref(earningId ? '修改季度收益' : '添加季度收益')
    /* 开始 用户表单 **********************************/
    // 账户表单配置
    const userRef = ref()
    const userForm = reactive({
      companyId: '',
      userCount: 0,
      contractTime: '',
      year: '',
      quarter: 1,
      contractAmount: 0,
      incomeAmount: 0,
      costAmount: 0,
      profitAmount: 0
    })
    // 单位名下拉列表
    const unitOptions = ref([])
    const quarterData = ref([
      { label: '第一季度', value: 1 },
      { label: '第二季度', value: 2 },
      { label: '第三季度', value: 3 },
      { label: '第四季度', value: 4 }
    ])

    // 账户新增修改校验
    const userFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      userCount: [{ required: true, message: '请输入人数', trigger: 'blur' }],
      contractTime: [{ required: true, message: '请选择合同时间', trigger: 'blur' }],
      year: [{ required: true, message: '请选择年', trigger: 'blur' }],
      quarter: [{ required: true, message: '请选择季度', trigger: 'blur' }],
      contractAmount: [{ required: true, message: '请输入合同总金额', trigger: 'blur' }],
      incomeAmount: [{ required: true, message: '请输入收入金额', trigger: 'blur' }],
      costAmount: [{ required: true, message: '请输入成本金额', trigger: 'blur' }],
      profitAmount: [{ required: true, message: '请输入利润金额', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitUser = () => {
      userRef.value.CustomFormRef.validate()
        .then(() => {
          // 处理参数
          const userFormParams = searchParams(userForm, ['contractTime', 'year'], true)
          if (userForm.contractTime) {
            userFormParams.contractTime = formetData(userForm.contractTime, 'year')
          }
          if (userForm.year) {
            userFormParams.year = new Date(userForm.year).getFullYear()
          }
          if (content.value === '添加季度收益') {
            dispatch('fetchAddQuarterlyEarning', userFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/quarterlyEarning' })
              }
            })
          } else {
            userFormParams.earningId = earningId
            dispatch('fetchUpdateQuarterlyEarning', userFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/quarterlyEarning' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelUser = () => {
      router.push({ path: '/quarterlyEarning' })
    }
    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []

      if (content.value === '修改季度收益') {
        await dispatch('fetchGetQuarterlyEarning', earningId).then(data => {
          if (data && data.code === 200) {
            Object.keys(userForm).map(item => {
              if (item === 'contractTime') {
                if (data.data[item]) {
                  userForm[item] = data.data[item].split(' ')[0]
                }
              } else if (item === 'year') {
                if (data.data[item]) {
                  userForm[item] = new Date(data.data[item].toString())
                }
              } else {
                userForm[item] = data.data[item]
              }
            })
          }
        })
      }
    })
    return {
      userRef,
      content,
      userForm,
      unitOptions,
      userFormRules,
      clickSubmitUser,
      clickCancelUser,
      tabberHeight,
      quarterData
    }
  }
}
</script>

<style lang="scss">
.user-action {
  position: relative;
}
</style>
